@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
@import url(plug/jquery.fancybox.min.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* > reset */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

tr,
th,
td {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

@font-face {
  font-family: "condensed-extrabold";
  src: url(../assets/condensed_extra_bold.ttf); }

@font-face {
  font-family: "condensed-bold";
  src: url(../assets/condensed_bold.ttf); }

@font-face {
  font-family: "condensed-Regular";
  src: url(../assets/condensed.ttf); }

.fen_eb {
  font-family: 'condensed-extrabold', sans-serif; }

.fen_b, .indx-banner .slogn h2 + div {
  font-family: 'condensed-bold', sans-serif; }

.fen, .lang_en .en, .pgtitle h6, .indx .mtitle h2, .indx-banner .slogn h2, .indx-about h2, .prod .txt h4, #top-menu-ul .item_menu_Box a {
  font-family: 'condensed-Regular', sans-serif; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
.editor_Box, main[role^=main] {
  zoom: 1; }
  .editor_Box:before, .editor_Box:after, main[role^=main]:before, main[role^=main]:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  .editor_Box:after, main[role^=main]:after {
    clear: both; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

p::selection, h1::selection, h2::selection, h3::selection, h4::selection, b::selection, strong::selection, span::selection, li::selection, div::selection, a::selection, img::selection {
  color: #fff;
  background: #000; }

a {
  outline: none; }

a, a:hover {
  text-decoration: none;
  transition: 0.4s;
  color: initial; }

a[href^=tel] {
  color: #413523;
  cursor: default; }
  a[href^=tel]:hover {
    color: #413523; }

*:focus {
  outline: none; }

img {
  vertical-align: middle;
  max-width: 100%; }

ul {
  list-style-type: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*----------------------編輯器------------------------*/
.editor_Box {
  width: 100%;
  font-size: 0.9375rem;
  color: black;
  font-weight: 400;
  line-height: 1.5; }
  .editor_Box ul, .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
    @media screen and (max-width: 768px) {
      .editor_Box img {
        width: 100%; } }
  .editor_Box iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Box iframe {
        height: auto !important; } }
  .editor_Box h1, .editor_Box h2, .editor_Box h3, .editor_Box h4, .editor_Box h5, .editor_Box h6 {
    margin: inherit; }
  .editor_Box p {
    margin-bottom: 18px; }
    .editor_Box p:last-child {
      margin-bottom: 0; }

/*----------------------頁籤------------------------*/
.page {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }
  .page dt, .page dd {
    display: inline-block;
    height: 20px; }
  .page a {
    font-size: 1rem;
    display: block;
    line-height: 20px;
    color: #6A6A6A; }
  .page a.active {
    color: #ff777e; }
  .page dt a .svg {
    width: 16px;
    height: 18px;
    padding-top: 2px; }
    .page dt a .svg path {
      fill: #6A6A6A; }
  @media (min-width: 641px) {
    .page a {
      margin: 0 20px; }
    .page dd a:hover {
      color: #ff777e; }
    .page dt a:hover .svg path {
      fill: #ff777e; } }
  @media (min-width: 992px) {
    .page a {
      margin: 0 25px; }
    .page .ltbn {
      margin-right: 20px; }
    .page .rtbn {
      margin-left: 20px; } }
  @media (max-width: 640px) {
    .page {
      justify-content: space-around; } }
  .page .nopage {
    opacity: 0;
    z-index: -1; }

/*----------------------go top------------------------*/
#topBtn {
  cursor: pointer;
  display: block;
  z-index: 1;
  position: fixed;
  right: 15px;
  bottom: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s;
  background: no-repeat url(../images/gotop.png) top center/contain;
  line-height: 0.8;
  letter-spacing: 0.8px;
  font-size: 0.875rem;
  color: black;
  font-weight: 400;
  padding-top: 40px; }
  #topBtn.open {
    opacity: 1;
    visibility: visible;
    z-index: 100; }
  #topBtn.open {
    opacity: 1;
    z-index: 100; }
  #topBtn.change {
    position: absolute; }
  @media (min-width: 641px) {
    #topBtn:hover {
      opacity: 0.5; } }
  @media (max-width: 640px) {
    #topBtn.change {
      color: white; } }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.mpsrel {
  position: relative; }

.hide {
  display: none; }

.w10img {
  width: 100%; }

.pos-middle, .pgtitle .txt, .indx-banner .bgcover::after, .indx-banner .slogn, .prod .bgcolor, .prod .txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.bgcover {
  width: 100%;
  height: 0;
  background: center / cover; }

.default_motion, header ul.mmenu > li a, header ul.mmenu > li a span:after, .indx-banner .bgcover::after, .prod .bgcolor {
  transition: all 0.6s; }

@media (min-width: 769px) {
  .show-sm {
    display: none; } }

@media (max-width: 768px) {
  .show-lg {
    display: none; }
  span.br {
    display: block; } }

@keyframes lengthen {
  0% {
    height: 0%; }
  90% {
    height: 76px; }
  100% {
    height: 76px; } }

@-webkit-keyframes lengthen {
  0% {
    height: 0%; }
  90% {
    height: 76px; }
  100% {
    height: 76px; } }

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  transition: all 1s; }
  header .logo {
    width: 185px;
    display: block; }
    @media (max-width: 768px) {
      header .logo {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%); } }
    @media (max-width: 767px) {
      header .logo {
        width: 150px;
        left: 4.5%; } }
  header ul.mmenu {
    height: 100%; }
    header ul.mmenu > li {
      margin: 0 25px;
      position: relative;
      text-align: center; }
      header ul.mmenu > li a {
        z-index: 11;
        position: relative;
        display: inline-block;
        line-height: 40px;
        letter-spacing: 0.8px;
        text-align: left;
        color: #ffffff;
        font-size: 1rem;
        color: white;
        font-weight: 400; }
      header ul.mmenu > li a span:after {
        content: " ";
        display: block;
        width: 0%;
        height: 1px;
        background-color: #fcdb8e;
        transform: translateX(-50%);
        position: absolute;
        bottom: 0;
        left: 50%; }
      header ul.mmenu > li.active > a {
        color: #fcdb8e; }
      header ul.mmenu > li.active a span:after {
        width: 100%; }
      @media (min-width: 768px) {
        header ul.mmenu > li:hover > a, header ul.mmenu > li.active > a {
          color: #fcdb8e; }
        header ul.mmenu > li:hover a span:after, header ul.mmenu > li.active a span:after {
          width: 100%; } }
      @media (max-width: 767px) {
        header ul.mmenu > li {
          margin: 12% 0; } }
    @media (min-width: 768px) {
      header ul.mmenu {
        display: flex;
        justify-content: flex-end;
        align-items: center; } }
    @media (max-width: 767px) {
      header ul.mmenu {
        padding-top: 15px; } }
  header .menuicon {
    position: absolute;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    z-index: 10; }
    header .menuicon .mpsrel {
      width: 37px;
      height: 37px; }
    header .menuicon .bar, header .menuicon .bar:after, header .menuicon .bar:before {
      transition: all .25s;
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      height: 2px;
      width: 20px;
      background: white;
      transform: translateX(-50%); }
    header .menuicon .bar {
      margin-top: 18px; }
    header .menuicon .bar:after {
      top: 7px; }
    header .menuicon .bar:before {
      top: -7px; }
    @media (min-width: 768px) {
      header .menuicon {
        display: none; } }
    @media (max-width: 767px) {
      header .menuicon {
        right: 3.5%; } }
  header .menu-expanded .menuicon .bar {
    background: rgba(255, 255, 255, 0); }
    header .menu-expanded .menuicon .bar:before {
      top: 0px;
      transform: translateX(-50%) rotate(-45deg);
      background-color: white; }
    header .menu-expanded .menuicon .bar:after {
      top: 0px;
      transform: translateX(-50%) rotate(45deg);
      background-color: white; }
  @media (min-width: 768px) {
    header {
      background-color: rgba(0, 0, 0, 0.7); }
      header .headbg {
        max-width: 1230px;
        height: 80px;
        padding: 0 15px;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center; }
      header .navBox {
        height: 25px;
        text-align: right; } }
  @media (max-width: 767px) {
    header .headBox {
      position: relative;
      width: 100%;
      z-index: 10;
      height: 80px;
      background-color: rgba(0, 0, 0, 0.7); }
      header .headBox.menu-expanded {
        background-color: black; }
    header .navBox {
      display: none;
      width: 100%;
      height: 80vh;
      position: absolute;
      top: 80px;
      left: 0;
      z-index: 9;
      background-color: black; } }
  @media (max-width: 767px) {
    header .headBox {
      height: 60px; }
    header .navBox {
      height: calc(99vh - 60px);
      top: 60px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch; } }

.footerH {
  height: 80px; }
  @media (max-width: 640px) {
    .footerH {
      background-color: #2d4e37;
      height: 45px;
      width: 100%;
      margin-top: 50px; } }

footer {
  background-color: #2d4e37;
  line-height: 1.67; }
  footer .footerbox {
    max-width: 1230px;
    margin: 0 auto;
    padding: 15px;
    position: relative; }
  @media (min-width: 992px) {
    footer .footerbox {
      display: flex;
      justify-content: flex-start;
      position: relative; } }
  @media (max-width: 991px) {
    footer .footerbox {
      text-align: center; } }
  @media (max-width: 640px) {
    footer .footerbox {
      padding: 0  4.5%  15px;
      margin-top: -25px; } }
  @media (min-width: 992px) {
    footer .flogo {
      flex: 0 0 165px;
      margin-right: 3%; } }
  @media (max-width: 991px) {
    footer .flogo {
      width: 150px;
      margin: 0 auto; } }
  footer a[href^=tel], footer a {
    color: white;
    cursor: initial; }
    footer a[href^=tel]:hover, footer a:hover {
      color: white; }
  footer .info {
    font-size: 0.9375rem;
    color: white;
    font-weight: 300;
    letter-spacing: 0.8px; }
    @media (min-width: 992px) {
      footer .info {
        flex: 0 0 400px; }
        footer .info > span {
          margin-right: 10px;
          padding-top: 4px;
          display: inline-block; } }
    @media (max-width: 991px) {
      footer .info {
        margin: 15px 0; }
        footer .info > span {
          display: block; } }
    @media (max-width: 640px) {
      footer .info {
        font-size: 0.875rem; } }
  footer .copyright {
    letter-spacing: 0.7px;
    font-size: 0.8125rem;
    color: white;
    font-weight: 400; }
    @media (min-width: 991px) {
      footer .copyright {
        flex: 0 0 calc(100% - 565px);
        width: calc(100% - 565px);
        padding-top: 34px;
        text-align: right; } }
    @media (max-width: 640px) {
      footer .copyright {
        font-size: 0.75rem; } }

.lang_tw .en {
  display: none; }

.lang_tw .tw {
  display: block; }

.lang_en .en {
  display: block; }

.lang_en .tw {
  display: none; }

/******************************************/
/*		pages
/******************************************/
body {
  font-family: "Noto Sans TC", sans-serif;
  color: #666;
  line-height: 1.5;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
  font-size: 1rem;
  color: #acolor;
  font-weight: 400; }
  body.fade-in {
    opacity: 1;
    transition: opacity  2s ease;
    visibility: visible; }
  body.modal-open {
    overflow: hidden; }
    body.modal-open .overlay, body.modal-open .overbg {
      opacity: 1; }
    @media (min-width: 641px) {
      body.modal-open {
        padding-right: 17px; } }
  @media (min-width: 641px) {
    body {
      letter-spacing: 0.1em; } }
  @media (max-width: 640px) {
    body {
      letter-spacing: 0em; } }

html.modal-open, main {
  overflow: hidden; }

.row {
  max-width: 1130px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .row {
      padding: 0 15px; } }
  @media (max-width: 991px) {
    .row .col-12 {
      padding: 0 6.5%; } }

.rowbox {
  max-width: 1130px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative; }
  @media (max-width: 1200px) {
    .rowbox {
      padding: 0 30px; } }
  @media (max-width: 640px) {
    .rowbox {
      padding: 0 4.5%; } }

.mflex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.pgtitle {
  position: relative;
  text-align: center; }
  .pgtitle .txt {
    margin-top: 40px; }
  .pgtitle h6 {
    font-size: 2.5rem;
    color: #2d4e37;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 12px;
    text-shadow: .0em .0em 0.5em white; }
  .pgtitle h6 + div {
    font-size: 1.0625rem;
    color: #010101;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 1.7px;
    text-shadow: .0em .0em 0.5em white; }
  @media (min-width: 769px) {
    .pgtitle {
      height: 365px;
      margin-bottom: 75px; }
      .pgtitle .banner {
        height: 100%;
        background: no-repeat center / 2000px; } }
  @media (max-width: 768px) {
    .pgtitle {
      margin-bottom: 35px; }
      .pgtitle .banner.show-sm {
        padding-bottom: 60%; }
      .pgtitle h6 {
        font-size: 1.875rem;
        margin-bottom: 5px; }
      .pgtitle h6 + div {
        font-size: 0.9375rem; } }

.back {
  position: relative;
  width: 140px;
  height: 44px;
  border: 1px solid #2d4e37;
  background-color: white;
  color: black;
  display: block;
  padding-left: 55px;
  line-height: 40px; }
  .back i {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 1em;
    display: block;
    transform-origin: left top;
    transition: all 0.6s; }
  .back i:before {
    content: ' ';
    background: no-repeat url("../svg/back_line.svg") left top/9px 7px;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 4px;
    left: -1px;
    transition: all 0.6s; }
  .back i span {
    width: 20px;
    height: 1px;
    display: inline-block;
    background-color: black;
    position: absolute;
    top: 50%;
    right: 0%;
    margin-top: 2px;
    transition: width 0.6s; }
  @media (min-width: 641px) {
    .back:hover {
      background-color: #2d4e37;
      color: white; }
      .back:hover i:before {
        background: no-repeat url("../svg/back_line_wh.svg") left top/9px 7px;
        left: -12px; }
      .back:hover i span {
        width: 30px;
        background-color: white; } }

@media (min-width: 992px) {
  .indx header {
    background-color: rgba(0, 0, 0, 0); }
    .indx header.nav-up {
      background-color: rgba(0, 0, 0, 0.7); } }

@media (max-width: 991px) {
  .indx header .headBox {
    background-color: rgba(0, 0, 0, 0); }
  .indx header.nav-up .headBox {
    background-color: rgba(0, 0, 0, 0.7); }
  .indx header .headBox.menu-expanded {
    background-color: black; } }

@media (min-width: 768px) {
  .indx .row {
    max-width: 1130px;
    padding: 0 9px; }
    .indx .row .col-12 {
      padding-left: 6px;
      padding-right: 6px; } }

.indx .mtitle {
  position: relative;
  padding-left: 20px; }
  .indx .mtitle:after {
    content: ' ';
    width: 4px;
    background-color: #2d4e37;
    position: absolute;
    left: 0px; }
  .indx .mtitle h2 {
    font-size: 2.125rem;
    color: #2d4e37;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 23px; }
  .indx .mtitle h2 + div {
    font-size: 1.0625rem;
    color: black;
    font-weight: 400;
    line-height: 1; }
  @media (max-width: 640px) {
    .indx .mtitle {
      padding-left: 10px; }
      .indx .mtitle:after {
        width: 2px; }
      .indx .mtitle h2 {
        font-size: 1.5rem; }
      .indx .mtitle h2 + div {
        font-size: 0.9375rem; } }

.indx .mmore {
  font-size: 0.9375rem;
  color: black;
  font-weight: 400;
  position: relative;
  display: inline-block;
  width: 142px;
  line-height: 1; }
  .indx .mmore i:before {
    content: ' ';
    background: no-repeat url("../svg/more_line.svg") right top/8px 9px;
    width: 8px;
    height: 9px;
    display: block;
    position: absolute;
    top: 2px;
    right: 0; }
  .indx .mmore i span {
    width: calc(100% - 72px);
    height: 1px;
    transform: translateY(-3px);
    display: inline-block;
    background-color: black;
    transition: 0.6s;
    position: relative; }
  @media (min-width: 641px) {
    .indx .mmore:hover {
      width: 162px;
      color: #2d4e37; }
      .indx .mmore:hover i span {
        background-color: #2d4e37; }
      .indx .mmore:hover i:before {
        background-image: url("../svg/more_line_h.svg"); } }
  @media (max-width: 640px) {
    .indx .mmore {
      font-size: 0.875rem;
      width: 100px; }
      .indx .mmore i {
        width: calc(100% - 65px);
        margin-left: 5px; } }

.indx .scrlto {
  width: 100%;
  height: 1px;
  transform: translateY(-200px); }
  @media (max-width: 767px) {
    .indx .scrlto {
      transform: translateY(-100px); } }

.indx-banner .btmbg {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10; }
  @media (min-width: 768px) {
    .indx-banner .btmbg {
      height: 230px;
      background: url(../images/indx/banner-btm.png) bottom/2000px; }
      .indx-banner .btmbg img {
        display: none; } }

.indx-banner a {
  display: block;
  position: relative; }

.indx-banner .bgcover::after {
  content: ' ';
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 2; }

@media (min-width: 768px) {
  .indx-banner .bgcover {
    padding-bottom: 56.5%; } }

@media (max-width: 767px) {
  .indx-banner .bgcover {
    padding-bottom: 126%; } }

.indx-banner .slogn {
  color: white;
  letter-spacing: 0.2px;
  max-width: 1230px;
  margin-top: -40px;
  padding: 0 15px;
  width: 100%;
  line-height: 1; }
  .indx-banner .slogn h2 {
    font-size: 4.75rem;
    text-shadow: 0 0 27px rgba(0, 0, 0, 0.3); }
  .indx-banner .slogn h2 + div {
    font-size: 2.125rem;
    margin-top: 24px;
    text-shadow: 0 0 27px rgba(0, 0, 0, 0.3); }
  @media (max-width: 767px) {
    .indx-banner .slogn {
      margin-top: 0px; }
      .indx-banner .slogn h2 {
        font-size: 2.25rem; }
      .indx-banner .slogn h2 + div {
        font-size: 1.25rem;
        margin-top: 12px; } }

.indx-banner .slick-dots {
  width: 100%;
  max-width: 1130px;
  padding: 0 15px;
  margin: 0;
  text-align: right;
  z-index: 13; }
  .indx-banner .slick-dots li.slick-active button::before {
    background: #fcdb8e; }
  .indx-banner .slick-dots li {
    margin: 0 0 0 20px; }
  .indx-banner .slick-dots li button::before {
    background: #a0a0a0;
    top: 0;
    left: 0; }
  @media (min-width: 768px) {
    .indx-banner .slick-dots {
      bottom: 160px;
      left: 50%;
      transform: translateX(-50%); }
      .indx-banner .slick-dots li {
        width: 12px;
        height: 12px !important; }
      .indx-banner .slick-dots li button::before {
        width: 12px;
        height: 12px; } }
  @media (max-width: 767px) {
    .indx-banner .slick-dots {
      padding: 0 6.5%;
      bottom: 13%; }
      .indx-banner .slick-dots li {
        width: 9px;
        height: 9px !important; }
      .indx-banner .slick-dots li button::before {
        width: 9px;
        height: 9px; } }

.indx-banner .mscroll {
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  position: absolute;
  bottom: 160px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 12; }
  .indx-banner .mscroll a {
    width: 20px;
    display: block; }
  @media (max-width: 767px) {
    .indx-banner .mscroll {
      bottom: 10%; } }

.indx-about {
  position: relative;
  z-index: 10;
  font-size: 0.9375rem;
  color: black;
  font-weight: 400; }
  .indx-about h2 {
    font-size: 2.125rem;
    color: #2d4e37;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 1; }
  .indx-about h1 {
    font-size: 1.0625rem;
    color: black;
    font-weight: 400;
    letter-spacing: 1.7px;
    line-height: 1;
    margin: 12px 0 60px; }
  @media (min-width: 768px) {
    .indx-about {
      background: no-repeat url(../images/indx/indx-about-bg.png) center/2000px;
      height: 616px;
      margin-bottom: 50px;
      margin-top: -167px;
      padding-top: 170px;
      letter-spacing: 1.5px; } }
  @media (max-width: 767px) {
    .indx-about {
      font-size: 0.875rem;
      padding-top: 15px;
      margin-bottom: 40px; }
      .indx-about h2 {
        font-size: 1.5rem; }
      .indx-about h1 {
        font-size: 0.9375rem;
        margin-bottom: 30px; } }
  .indx-about .mflex {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .indx-about .mflex {
        margin-top: 75px; }
        .indx-about .mflex > div {
          flex: 0 0 33.333%;
          margin-bottom: 75px; } }
    @media (max-width: 767px) {
      .indx-about .mflex > div {
        flex: 0 0 100%;
        margin-top: 25px; } }

.indx-prod .mtitle {
  text-align: left;
  margin-bottom: 40px; }
  .indx-prod .mtitle h2 + div {
    margin-top: 12px; }
  .indx-prod .mtitle:after {
    top: -1px;
    height: 53px; }
  @media (max-width: 640px) {
    .indx-prod .mtitle {
      margin-bottom: 30px; }
      .indx-prod .mtitle h2 + div {
        margin-top: 2px; }
      .indx-prod .mtitle:after {
        top: 0px;
        height: 40px; } }

.indx-prod .mright {
  position: absolute;
  bottom: 36px; }
  @media (min-width: 768px) {
    .indx-prod .mright {
      left: calc(100% - 148px); } }
  @media (max-width: 767px) {
    .indx-prod .mright {
      right: 6.5%; } }

.indx-prod .items a {
  display: block;
  position: relative;
  overflow: hidden; }

@media (min-width: 769px) {
  .indx-prod .items a:hover .bgcolor {
    width: 220px;
    height: 105px; }
  .indx-prod .items.col-12 {
    margin-bottom: 12px; }
  .indx-prod .items .bgcover {
    height: 360px; } }

@media (max-width: 768px) {
  .indx-prod .items.col-12 {
    margin-bottom: 4%; }
  .indx-prod .items .bgcover {
    padding-bottom: 66%; } }

.indx-line {
  background-color: #bfbfbf;
  height: 1px; }
  @media (min-width: 768px) {
    .indx-line {
      max-width: 1200px;
      width: calc(100% - 30px);
      margin: 40px auto 45px; } }
  @media (max-width: 767px) {
    .indx-line {
      width: 87%;
      margin: 15px auto 25px; } }

.indx-cert .mtitle {
  margin-bottom: 20px; }
  .indx-cert .mtitle:after {
    height: 23px;
    top: 1px; }
  .indx-cert .mtitle h2 {
    vertical-align: -3px;
    margin-right: 8px; }
  .indx-cert .mtitle h2, .indx-cert .mtitle h2 + div {
    display: inline-block; }
  @media (max-width: 640px) {
    .indx-cert .mtitle:after {
      height: 20px;
      top: 5px; } }

.indx-cert .mmore {
  margin-left: 20px; }

.indx-cert .slider-cert {
  text-align: right; }
  .indx-cert .slider-cert a {
    display: inline-block; }

@media (max-width: 991px) {
  .indx-cert .slider-cert {
    margin-top: 50px;
    text-align: center; } }

@media (max-width: 640px) {
  .indx-cert .mmore {
    margin-left: 10px; }
  .indx-cert .slick-initialized .slick-slide {
    padding: 0 20px; }
  .indx-cert .btn_prev, .indx-cert .btn_next {
    z-index: 10;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 15px;
    display: block;
    cursor: pointer; }
  .indx-cert .btn_prev {
    left: -15px; }
  .indx-cert .btn_next {
    right: -15px; } }

.prod {
  text-align: center; }
  .prod .bgcolor {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 2; }
  .prod .txt {
    z-index: 3; }
  .prod .txt h4 {
    font-size: 2.125rem;
    color: white;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 30px;
    margin-bottom: 2px; }
    @media (max-width: 768px) {
      .prod .txt h4 {
        font-size: 1.5625rem;
        line-height: 25px; } }
  .prod .txt span {
    font-size: 1.25rem;
    color: white;
    font-weight: 400;
    letter-spacing: 0.1px; }
    @media (max-width: 768px) {
      .prod .txt span {
        font-size: 0.9375rem; } }
  .prod .back {
    margin-top: 45px; }

.prodlist a {
  display: block;
  position: relative; }

.prodlist .bgcover {
  padding-bottom: 66%; }

@media (min-width: 769px) {
  .prodlist {
    max-width: 1130px;
    padding: 0 9px; }
    .prodlist a:hover .bgcolor {
      width: 220px;
      height: 105px; }
    .prodlist .col-12 {
      padding-left: 6px;
      padding-right: 6px;
      margin-bottom: 12px; } }

@media (max-width: 768px) {
  .prodlist .col-12 {
    margin-bottom: 4%; } }

.prodbanner {
  margin-bottom: 25px; }
  @media (min-width: 769px) {
    .prodbanner .bgcover {
      height: 360px; } }
  @media (max-width: 768px) {
    .prodbanner .bgcover {
      padding-bottom: 66%; } }

.prodtable h2 {
  letter-spacing: 0.9px;
  line-height: 1.2;
  font-size: 1.0625rem;
  color: #2d4e37;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center; }
  @media (max-width: 640px) {
    .prodtable h2 {
      font-size: 0.9375rem;
      margin-bottom: 12px; } }

@media (min-width: 992px) {
  .prodtable {
    max-width: 1130px; }
    .prodtable ul.items {
      margin-bottom: 35px;
      display: table;
      border: 1px solid #2d4e37;
      width: 100%;
      font-size: 0.9375rem;
      color: black;
      font-weight: 400;
      letter-spacing: 0.8px; }
    .prodtable li {
      display: table-row; }
    .prodtable li.thead {
      background-color: #2d4e37; }
      .prodtable li.thead div {
        line-height: 40px;
        text-align: center;
        color: white;
        padding: 0; }
    .prodtable div {
      vertical-align: middle;
      display: table-cell;
      border: solid 1px rgba(45, 78, 55, 0.2);
      width: 78px;
      padding: 15px 13px;
      text-align: center; }
    .prodtable div:nth-child(1), .prodtable div:nth-child(8) {
      width: 170px;
      text-align: left; }
    .prodtable div:nth-child(4) {
      width: 110px;
      text-align: left; }
    .prodtable div:nth-child(7) {
      width: calc(100% - 762px);
      text-align: left; }
    .prodtable i {
      display: none; } }

@media (max-width: 991px) {
  .prodtable ul.items {
    max-width: 670px;
    margin: 0 auto 30px;
    font-size: 0.875rem;
    color: black;
    font-weight: 400;
    letter-spacing: 0.8px; }
  .prodtable li.thead {
    display: none; }
  .prodtable li {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    border: 1px solid #2d4e37; }
  .prodtable div {
    flex: 0 0 100%;
    padding: 10px;
    border-bottom: 1px solid rgba(45, 78, 55, 0.2);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
  .prodtable div:nth-child(1) {
    background-color: #2d4e37;
    color: white; }
    .prodtable div:nth-child(1) span {
      text-align: right; }
  .prodtable div:nth-child(2), .prodtable div:nth-child(3), .prodtable div:nth-child(4), .prodtable div:nth-child(5) {
    flex: 0 0 50%; }
  .prodtable div:nth-child(2), .prodtable div:nth-child(4) {
    border-right: 1px solid rgba(45, 78, 55, 0.2); }
  .prodtable div:nth-child(7) > *, .prodtable div:nth-child(8) > * {
    flex: 0 0 100%; }
  .prodtable div:nth-child(7) i, .prodtable div:nth-child(8) i {
    margin-bottom: 12px; }
  .prodtable span.mflex {
    display: flex;
    justify-content: center;
    align-items: center; }
    .prodtable span.mflex img {
      height: 25px; } }

.certificate.items {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }
  .certificate.items > div {
    flex: 0 0 20%;
    text-align: center;
    margin-bottom: 50px; }
  .certificate.items a div {
    height: 80px; }
    .certificate.items a div img {
      height: 100%;
      max-width: initial; }
  .certificate.items a span {
    font-size: 1.0625rem;
    color: black;
    font-weight: 400;
    margin-top: 15px;
    letter-spacing: 0.9px;
    height: 2.6em;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* 第幾行要顯示點點點 */
    -webkit-box-orient: vertical; }
  @media (min-width: 641px) and (max-width: 991px) {
    .certificate.items > div {
      flex: 0 0 33.3333%; } }
  @media (min-width: 641px) {
    .certificate.items a:hover div {
      opacity: 0.5; }
    .certificate.items a:hover span {
      color: #2d4e37; } }
  @media (max-width: 640px) {
    .certificate.items > div {
      flex: 0 0 50%;
      margin-bottom: 20px; }
    .certificate.items a div {
      height: 60px; }
    .certificate.items a span {
      font-size: 0.9375rem; } }

.certificate.single .myear {
  font-size: 1.0625rem;
  color: black;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1; }

.certificate.single h1 {
  margin: 10px 0 0px;
  font-size: 1.0625rem;
  color: black;
  font-weight: 400;
  letter-spacing: 1px; }

.certificate.single .editor_Box {
  border-top: 1px solid #a0a0a0;
  padding-top: 40px;
  margin-bottom: 60px;
  margin-top: 16px; }

@media (max-width: 640px) {
  .certificate.single .myear, .certificate.single h1 {
    font-size: 0.9375rem; }
  .certificate.single .editor_Box {
    margin-bottom: 40px;
    margin-top: 6px;
    padding-top: 20px; } }

#top-menu-ul {
  overflow: hidden;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #a0a0a0; }
  @media (max-width: 640px) {
    #top-menu-ul {
      margin-bottom: 30px; } }
  #top-menu-ul .item_Menu {
    width: 100%;
    margin: 0 auto; }
  #top-menu-ul .item_menu_Box {
    height: 45px;
    padding-top: 5px;
    width: 100%;
    overflow: hidden;
    position: relative;
    text-align: center; }
    @media (max-width: 640px) {
      #top-menu-ul .item_menu_Box {
        padding-top: 3px;
        height: 30px; } }
    #top-menu-ul .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      width: auto;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: inline-block; }
      @media screen and (max-width: 441px) {
        #top-menu-ul .item_menu_Box .item_menu_list {
          overflow-y: hidden;
          overflow-x: scroll;
          width: 100%; } }
    #top-menu-ul .item_menu_Box li {
      display: inline-block;
      margin: 0 20px; }
    #top-menu-ul .item_menu_Box a {
      font-size: 1.875rem;
      color: #a0a0a0;
      font-weight: 400;
      position: relative;
      display: block; }
      #top-menu-ul .item_menu_Box a div {
        line-height: 20px;
        letter-spacing: 0px; }
      #top-menu-ul .item_menu_Box a:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 5px;
        margin-top: 14px;
        background-color: #2d4e37;
        opacity: 0;
        transition: all 0.6s; }
    #top-menu-ul .item_menu_Box li.active a {
      color: #2d4e37; }
      #top-menu-ul .item_menu_Box li.active a:after {
        opacity: 1; }
    @media (min-width: 641px) {
      #top-menu-ul .item_menu_Box a:hover {
        color: #2d4e37; }
        #top-menu-ul .item_menu_Box a:hover:after {
          opacity: 1; } }
    @media (min-width: 641px) and (-ms-high-contrast: none) {
      #top-menu-ul .item_menu_Box a:after {
        margin-top: 15px; } }
    @media (max-width: 640px) {
      #top-menu-ul .item_menu_Box a {
        font-size: 1.5625rem; }
        #top-menu-ul .item_menu_Box a:after {
          height: 2px;
          margin-top: 5px; } }

.flex-direction-nav {
  display: none; }
  .flex-direction-nav a {
    display: block;
    width: 20px;
    height: 45px;
    text-indent: -9999px;
    position: absolute;
    top: 0px;
    z-index: 10;
    background-color: white; }
    .flex-direction-nav a:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
  .flex-direction-nav a.flex-next {
    right: 0px; }
    .flex-direction-nav a.flex-next:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 7px;
      border-color: transparent transparent transparent #2d4e37;
      right: 0; }
    .flex-direction-nav a.flex-next.noright {
      background-color: white; }
      .flex-direction-nav a.flex-next.noright:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 7px;
        border-color: transparent transparent transparent #2d4e37; }
  .flex-direction-nav a.flex-prev {
    left: 0px; }
    .flex-direction-nav a.flex-prev:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 7px 6px 0;
      border-color: transparent #2d4e37 transparent transparent;
      left: 0; }
    .flex-direction-nav a.flex-prev.noleft {
      background-color: white; }
      .flex-direction-nav a.flex-prev.noleft:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 7px 6px 0;
        border-color: transparent #2d4e37 transparent transparent; }
  @media (max-width: 640px) {
    .flex-direction-nav {
      display: none; }
      .flex-direction-nav a {
        height: 30px; } }

.open_flexslider .item_menu_list {
  position: absolute;
  left: 0; }

.open_flexslider .flex-direction-nav {
  display: block; }

@media (max-width: 640px) {
  .open_flexslider .flex-direction-nav {
    display: none !important; } }

.form_des {
  font-size: 0.9375rem;
  color: #222;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
  letter-spacing: 1px; }
  .form_des i {
    color: #a70000; }
  @media (max-width: 640px) {
    .form_des {
      font-size: 0.875rem;
      margin-bottom: 20px; } }

form.row {
  max-width: 1130px; }
  form.row input {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    background-color: #eee;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 0.9375rem; }
  form.row textarea {
    width: 100%;
    padding: 5px 10px;
    background-color: #eee;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 0.9375rem; }
  form.row label {
    font-size: 0.9375rem;
    color: #222;
    font-weight: 400;
    word-spacing: 11px; }
    @media (max-width: 992px) {
      form.row label {
        margin-bottom: 3px; } }
    @media (max-width: 640px) {
      form.row label {
        font-size: 0.875rem; } }
  form.row label i {
    color: #a70000;
    margin-left: -8px; }
  form.row .form-group {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 0; }
    form.row .form-group.mtop {
      align-items: flex-start; }
  form.row .with-errors {
    flex: 0 0 100%;
    min-height: 20px;
    line-height: 20px;
    margin: 2px 0 10px;
    font-size: 0.8125rem;
    color: #9b0107;
    font-weight: 400;
    display: block; }
    form.row .with-errors .list-unstyled li:before {
      content: ' ';
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 2px;
      background: url("../images/required.png") no-repeat left 1px/8px; }
    @media (min-width: 992px) {
      form.row .with-errors {
        padding-left: 85px; } }
    @media (max-width: 991px) {
      form.row .with-errors {
        padding: 0 6.5%;
        margin-bottom: 5px; } }
  form.row button[type="submit"] {
    padding: 0;
    width: 140px;
    height: 44px;
    line-height: 40px;
    border: 1px solid #2d4e37;
    background-color: white;
    text-align: center;
    display: inline-block;
    border: 1px solid #2d4e37;
    cursor: pointer;
    font-size: 0.875rem;
    color: black;
    font-weight: 400;
    margin-top: 15px; }
    @media (min-width: 641px) {
      form.row button[type="submit"] {
        font-size: 0.9375rem; }
        form.row button[type="submit"]:hover {
          background-color: #2d4e37;
          color: white; } }
  @media (min-width: 992px) {
    form.row {
      padding: 0 15px; }
      form.row .col-md-2 {
        flex: 0 0 70px;
        padding: 0; }
      form.row .col-md-10 {
        flex: 0 0 calc(100% - 70px);
        padding: 0; }
      form.row textarea {
        height: 360px; }
      form.row input {
        height: 46px; } }
