//overwrite
body{
    font-family: $mfont;
    color: #666;
    line-height:1.5;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    &.fade-in{
        opacity: 1;
        transition:opacity  2s ease;
        visibility: visible;
    }
    &.modal-open{
        overflow: hidden;
       
        .overlay,.overbg{
            opacity: 1;            
        }
        @media (min-width:641px){
            padding-right: 17px;
        }
    }
    @include font-color(pxToEm(16),#acolor);    
    @media (min-width:641px){
        letter-spacing: 0.1em;
    }
    @media (max-width:640px){
        letter-spacing: 0em;
    }
}
html.modal-open,main{
    overflow: hidden;
}
.row{
    max-width:1130px;
    margin:0 auto;
    @media (min-width: 992px){
        padding:0 15px;
        // max-width:640px;
    }
    @media (max-width: 991px){
        .col-12{
            padding:0 6.5%;
        }
    }
}
.rowbox{
    max-width:1130px;
    padding:0 15px;
    margin:0 auto;
    position: relative;
    @media (max-width: 1200px){
        padding:0 30px;
    }
    @media (max-width: 640px){
        padding:0 4.5%;
    }
}

.mflex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.pgtitle{
    position: relative;
    text-align: center;
    .txt{
        @extend .pos-middle ;
        margin-top: 40px;
    }
    h6{
        @include font-color(pxToEm(40),$green,500);
        line-height: 28px;
        margin-bottom: 12px;
        @extend .fen;
        text-shadow: .0em .0em 0.5em white;
    }
    h6 + div{
        @include font-color(pxToEm(17),#010101 ,500);
        line-height: 17px;
        letter-spacing: 1.7px;
        text-shadow: .0em .0em 0.5em white;
    }
    
    @media (min-width:769px){        
        height: 365px;
        margin-bottom: 75px;
        .banner{
            height: 100%;
            background: no-repeat center / 2000px;
        }
    }
    @media (max-width:768px){
        margin-bottom: 35px;
        .banner.show-sm{
            padding-bottom: 60%;
        }
        h6{
            font-size: pxToEm(30);
            margin-bottom: 5px;
        }
        h6 + div{
            font-size: pxToEm(15);
        }
    }
}
.back{
    position: relative;
    width:140px;
    height: 44px;
    border:1px solid $green;
    background-color: white;
    color:black;
    display: block;
    padding-left: 55px;
    line-height: 40px;
    i{
        @include position(absolute,$left:24px,$top:50%);        
        transform: translate(0%,-50%);
        width: 20px;
        height:1em;
        display: block;
        transform-origin: left top;
        transition: all 0.6s;
    }
    i:before{
      content:' ';
      background:no-repeat url('../svg/back_line.svg') left top /9px 7px; 
      width:100%;
      height:100%; 
      display:block;
      position: absolute;
      top:4px;
      left:-1px;
      transition:all 0.6s;
    }
    i span{
      width:20px;
      height:1px;
      display:inline-block;
      background-color:black;
      position: absolute;
      top:50%;
      right:0%;
      margin-top: 2px;
      transition:width 0.6s;
    }
    @media (min-width: 641px)  {
        &:hover{
            background-color: $green;
            color:white;
            i:before{
                background:no-repeat url('../svg/back_line_wh.svg') left top /9px 7px; 
                left:-12px;
            }
            i span{
                width: 30px;
                background-color: white;
            }
        }
    }
    @media (max-width: 640px){
        
    }
}