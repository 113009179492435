.footerH {
    height: 80px; 
    @media (max-width:640px){
        background-color: $green;
        height: 45px;
        width:100%;
        margin-top: 50px;
    }   
}

footer {
    background-color: $green;
    line-height: 1.67;
    .footerbox{
        max-width: 1230px;
        margin:0 auto;
        padding:15px;
        position: relative;
    }
    
    @media (min-width:992px){
        .footerbox{            
            display: flex;
            justify-content: flex-start;
            position: relative;
        }
    }
    @media (max-width:991px){        
        .footerbox{
            text-align: center;
        }
    }
    @media (max-width:640px){        
        .footerbox{
            padding:0  4.5%  15px;
            margin-top: -25px;
        }
    }
    .flogo{
        
        @media (min-width:992px){
            flex: 0 0 165px;
            margin-right: 3%;
        }
        @media (max-width:991px){
            width:150px;
            margin:0 auto;
        }
    }
    a[href^=tel],a {
        color: white;     
        cursor: initial;
        &:hover{
            color: white;     
        }  
    }
    
    .info {
		@include font-color(pxToEm(15),white,300);
        letter-spacing: 0.8px;
           
        @media (min-width:992px){
            flex:0 0 400px;
            > span {
                margin-right: 10px;
                padding-top: 4px;
                display: inline-block;
            }  
        }
        @media (max-width:991px){
            margin:15px 0;
            > span{
                display: block;
            }
        }
        @media (max-width:640px){   
            font-size: pxToEm(14);
        }
    }
    .copyright {
        letter-spacing: 0.7px;
        @include font-color(pxToEm(13), white);
        @media (min-width: 991px) {
            flex:0 0 calc(100% - 565px);
            width: calc(100% - 565px);
            padding-top: 34px;
            text-align: right;
        }
        @media (max-width:640px){   
            font-size: pxToEm(12);
        }
    }
}