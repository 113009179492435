header{
    position: fixed;
	top:0;
    left:0;
	z-index: 999;
    width:100%;
    transition: all 1s;
	.logo{
        width:185px;
        display: block;
		@media (max-width: 768px){
            @include position(absolute, $left:30px, $top:50%);
            transform: translateY(-50%);
        }
        @media (max-width: 767px){
            width: 150px;
            left:4.5%;
        }
    }
    ul.mmenu{       
        height: 100%;
		> li{
            margin:0 25px;
            position: relative;
            text-align:center;	
            a{
                z-index: 11;
                position: relative;
                display:inline-block;
                line-height: 40px;
                letter-spacing: 0.8px;
                text-align: left;
                color: #ffffff;
                @include font-color(pxToEm(16),white);
                @extend .default_motion ;
            }     
            a span:after{
                content: " ";
                display: block;
                width:0%;
                height: 1px;
                background-color: $yel;
                transform: translateX(-50%);
                @include  position(absolute, $bottom:0, $left:50%);
                @extend .default_motion ;
            }
            &.active{
                > a{
                   color:$yel;
                }
                a span:after{
                    width:100%;
                }
            }            
            @media (max-width:991px){
               
            }
            @media (min-width:768px){
                
                &:hover,&.active{
                    > a{
                       color:$yel;
                    }
                    a span:after{
                        width:100%;
                    }
                }
            }
            @media (max-width:767px){
                margin:12% 0;
            }
        }
		@media (min-width: 768px){
            display: flex;
            justify-content:flex-end;
            align-items: center;
        }
        @media (max-width:767px){
            padding-top: 15px;
        }
	}//end mmenu

    .menuicon{
        position: absolute;
        @include position(absolute, $right:30px, $top:50%);
        transform: translateY(-50%);
		z-index: 10;       
        .mpsrel{
            width:37px;
            height:37px;
        }
        .bar,.bar:after,.bar:before{
            transition: all .25s;
            content: "";
            position: absolute;
            left: 50%;	  
            top:0;
            height: 2px;
            width: 20px;
            background:white;
            transform: translateX(-50%);
        }
        .bar{
            margin-top: 18px;
        }
        .bar:after{top: 7px;}
        .bar:before{top: -7px;}	
		
		@media (min-width: 768px){
			display: none;
		}
		@media (max-width: 767px){
            right:3.5%;
		}
    }
    .menu-expanded .menuicon .bar{
        background:rgba(white,0);
        &:before{
            top:0px;
            transform: translateX(-50%) rotate(-45deg);
            background-color: white;

        }
        &:after{
            top:0px;
            transform: translateX(-50%) rotate(45deg);
            background-color: white;

        }
    }
    @media (min-width:768px){
        
        background-color: rgba(black,0.7);
        
        .headbg{
            max-width: 1230px;
            height: 80px;
            padding:0 15px;
            margin:0 auto;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .navBox{
            height: 25px;
            text-align: right;
        }
    }
    @media (max-width:767px){	
        .headBox{
			position: relative;
			width: 100%;
            z-index: 10;
            height: 80px;
            background-color: rgba(black,0.7);
            &.menu-expanded{
                background-color: rgba(black,1);
            }
        }
        .navBox{
            display: none;
            width:100%;           
            height:80vh;
            position: absolute;
			top:80px;
			left:0;
            z-index: 9;
            background-color: black;
        }
    }
    @media (max-width:767px){	
        .headBox{
            height: 60px;
        }
        .navBox{
            height: calc(99vh - 60px);
            top:60px;
            overflow-y:scroll; 
			-webkit-overflow-scrolling: touch;
        }
    }
}
