.form_des{
    @include font-color(pxToEm(15), #222);
    text-align: center;
    margin-bottom: 50px;
    letter-spacing: 1px;
    i{
        color:#a70000;
    } 
    @media (max-width:640px){
        font-size: pxToEm(14);
        margin-bottom: 20px;
    }
}
form.row{
    max-width: 1130px;
    input{
        width:100%;
        height: 35px;
        padding:5px 10px;
        background-color:#eee;
        border: none;
        @include border(0); 
        font-size: pxToEm(15);
    }
    textarea{
        width:100%;
        padding:5px 10px;
        background-color:#eee;
        border: none;
        @include border(0); 
        font-size: pxToEm(15);
    }
    label{
        @include font-color(pxToEm(15), #222); 
        word-spacing: 11px;
        @media (max-width:992px){
            margin-bottom: 3px;
        }
        @media (max-width:640px){
            font-size: pxToEm(14);
        }
    }
    label i{
        color:#a70000;
        margin-left: -8px;
    }
    .form-group{
        margin-left:-15px;
        margin-right: -15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        &.mtop{
            align-items: flex-start;
        }
    }
    .with-errors{
        flex:0 0 100%;
        min-height:20px;
        line-height:20px;
        margin:2px 0 10px;
        @include font-color(pxToEm(13), #9b0107);  
        display:block;
        .list-unstyled li:before{
            content:' ';
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 2px;
            background:url("../images/required.png") no-repeat left 1px/8px;
        }
        @media (min-width:992px){
            padding-left: 85px;
        }
        @media (max-width:991px){
            padding: 0 6.5%;
            margin-bottom: 5px;
        }
    }
    button[type="submit"]{
        padding: 0;
        width:140px;
        height: 44px;
        line-height: 40px;
        border:1px solid $green;
        background-color: white;
        text-align: center;
        display: inline-block;
        border:1px solid $green;
        cursor: pointer;
        @include font-color(pxToEm(14),black);
        margin-top: 15px;
        @media (min-width:641px){
            font-size: pxToEm(15);
            &:hover{
                background-color: $green;
                color:white;
            }
        }        
    }
    @media (min-width:992px){        
        padding:0 15px;
        .col-md-2{
            flex:0 0 70px;
            padding: 0;
        }
        .col-md-10{
            flex:0 0 calc(100% - 70px);
            padding: 0;
        }
        textarea{
            height: 360px;
        }        
        input{
            height: 46px;
        }
    }
}