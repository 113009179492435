.certificate.items{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    > div{
        flex:0 0 20%;
        text-align: center;
        margin-bottom: 50px;
    }
    a div{
        height: 80px;
        img{
            height: 100%;
            max-width: initial;
        }
    }
    a span{
        @include font-color(pxToEm(17),black);
        margin-top: 15px;
        letter-spacing: 0.9px;
        @include clamp(1.3,2);
    }
    @media (min-width:641px) and (max-width:991px){
        > div{
            flex:0 0 33.3333%;
        }
    }
    @media (min-width:641px) {
        a:hover{
            div{
                opacity: 0.5;
            }
            span{
                color:$green;
            }
        }
    }
    @media (max-width:640px){
        > div{
            flex:0 0 50%;
            margin-bottom: 20px;
        }
        a div{
            height: 60px;
        }
        a span{
            font-size: pxToEm(15);
        }
    }
}
.certificate.single{
    .myear{
        @include font-color(pxToEm(17),black);
        letter-spacing: 1px;
        line-height: 1;
    }
    h1{
        margin:10px 0 0px;
        @include font-color(pxToEm(17),black);
        letter-spacing: 1px;
    }
    .editor_Box{
        border-top: 1px solid #a0a0a0;
        padding-top: 40px;
        margin-bottom: 60px;
        margin-top: 16px;
    }
    @media (max-width:640px){
        .myear,h1{
            font-size: pxToEm(15);
        }
        .editor_Box{
            margin-bottom: 40px;
            margin-top: 6px;
            padding-top: 20px;
        }
    }
}
#top-menu-ul{
    overflow: hidden;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    border-bottom:1px solid#a0a0a0; 
    @media (max-width:640px){
        margin-bottom: 30px;
    }
    .item_Menu{
       width:100%;
       margin: 0 auto;       
    }
    .item_menu_Box{
        height: 45px;      
        padding-top: 5px;  
        width:100%;
        overflow:hidden;
        position: relative;
        text-align:center;
        @media (max-width: 640px){
            padding-top: 3px;
            height: 30px; 
        }
        .item_menu_list{
            white-space: nowrap !important;
            width: auto;
            padding-bottom: 0px;
            text-align: center;
            font-size:0px;
            display:inline-block;
            
            @media screen and ( max-width: 441px ) {
                overflow-y: hidden;
                overflow-x: scroll;
                width:100%;          
            }
        }

        li{
            display: inline-block;     
            margin: 0 20px;
        }
        a{
            @include font-color(pxToEm(30),#a0a0a0,400);
            @extend .fen ;
            position: relative;
            display: block;
            div{
                line-height: 20px;
                letter-spacing: 0px;
            }
            &:after{
                content: ' ';
                display: block;
                width:100%;
                height: 5px;
                margin-top: 14px;
                background-color:$green;
                opacity: 0;
                transition: all 0.6s;
            }
        }
        li.active a{
            color:$green;
            &:after{
                opacity: 1;
            }
        }
        @media (min-width: 641px) {
            a:hover{
                color:$green;
                &:after{
                    opacity: 1;
                }
            }
        }
        @media (min-width: 641px) and (-ms-high-contrast:none){
            a{
                &:after{
                    margin-top: 15px;
                }
            }
        }
        @media (max-width: 640px){
            a{
                font-size: pxToEm(25);
                &:after{
                    height: 2px;
                    margin-top: 5px;
                }
            }
        }
    }
}
.flex-direction-nav{
    display:none;
    a{
        display:block;
        width:20px;
        height:45px;
        text-indent:-9999px;        
        position: absolute;
        top:0px;
        z-index: 10;
        background-color: white;
        &:after{
            @include position(absolute,$top:50%);
            transform: translateY(-50%);
        }
    }
    a.flex-next{
        right:0px;
        &:after{
            @include triangle_sosceles(right,6px,7px,$green);
            right:0;
        }
        &.noright{
            background-color: white;
            &:after{
                @include triangle_sosceles(right,6px,7px,$green);
            }
        }
    }

    a.flex-prev{
        left:0px;
        &:after{
            @include triangle_sosceles(left,6px,7px,$green);
            left:0;
        }
        &.noleft{
            background-color: white;
            &:after{
               @include triangle_sosceles(left,6px,7px,$green);
            }
            
        }
    }
    @media (max-width: 640px){
        display: none;
        a{height:30px;}
    }
}
.open_flexslider{
    .item_menu_list{
        position: absolute;
        left:0;
    }
    .flex-direction-nav{
        display: block;
    }
    @media (max-width: 640px){
        .flex-direction-nav{
            display: none !important;
        }
    }
}
