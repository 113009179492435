.indx{
    header{
        @media (min-width:992px){
            background-color: rgba(black,0);
            &.nav-up{
                background-color: rgba(black,0.7);
            }
        }
        @media (max-width:991px){	
            .headBox{
                background-color: rgba(black,0);
            }
            &.nav-up .headBox{
                background-color: rgba(black,0.7);
            }
            .headBox.menu-expanded{
                background-color: rgba(black,1);
            }
        }
    }
    
    .row{
        @media (min-width:768px){        
            max-width: 1130px;
            padding:0 9px;
            .col-12{
                padding-left:6px;
                padding-right: 6px;
            }
        }
    }
    .mtitle{
        position: relative;
        padding-left: 20px;
        &:after{
            content: ' ';
            width:4px;
            background-color: $green;
            @include position(absolute,$left:0px);
        }
        h2{
            @include font-color(pxToEm(34),$green);
            letter-spacing: 0.2px;
            line-height: 23px;
            @extend .fen ;
        }
        h2 + div{
            @include font-color(pxToEm(17),black);
            line-height: 1;
        }
        @media (max-width:640px){
            padding-left: 10px;
            &:after{
                width:2px;
            }
            h2{
                font-size: pxToEm(24);
            }
            h2 + div{
                font-size: pxToEm(15);
            }
        }
    }
    .mmore{
        @include font-color(pxToEm(15),black);
        position: relative;
        display: inline-block;
        width:142px;
        line-height:1;
        i:before{
            content:' ';
            background:no-repeat url('../svg/more_line.svg') right top /8px 9px; 
            width:8px;
            height:9px; 
            display:block;
            position: absolute;
            top:2px;
            right:0;
           
        }
        i span{
            width:calc(100% - 72px);
            height:1px;
            transform: translateY(-3px);
            display:inline-block;
            background-color:black;
            transition:0.6s;
            position: relative;
        }
        @media (min-width: 641px) {
            &:hover{
                width:162px;
                color:$green;
                i span{
                    background-color:$green;
                }
                i:before{
                    background-image:url('../svg/more_line_h.svg') ; 
                }
            }
        }
        @media (max-width: 640px){
            font-size:pxToEm(14);
            width: 100px;
            i {
                width: calc(100% - 65px);
                margin-left: 5px;
            }
  
        }
    }
    .scrlto{
        width:100%;
        height: 1px;
        transform: translateY(-200px);
        @media (max-width:767px){
            transform: translateY(-100px);
        }
    }
}
.indx-banner{
    .btmbg{
        width:100%;
        
        
        @include position(absolute,$left:0,$bottom:0);
        z-index: 10;
        @media (min-width:768px){
            height: 230px;
            background: url(../images/indx/banner-btm.png) bottom / 2000px;
            img{
                display: none;
            }
        }
        @media (max-width:767px){
        }
    }
    a{
        display: block;
        position: relative;
    }
    .bgcover{
        &::after{
            content:' ';        
            background-color: rgba(black,0.7);
            width: 100%;
            height: 100%;
            @extend .pos-middle ;
            z-index: 2;
            @extend .default_motion ;
        }
        @media (min-width:768px){
            padding-bottom: 56.5%;
        }
        @media (max-width:767px){
            padding-bottom: 126%;
        }
    }
    
    .slogn{
        color:white;
        letter-spacing: 0.2px;
        max-width:1230px;
        margin-top: -40px;
        padding:0 15px;
        width:100%;
        @extend .pos-middle ;
        line-height: 1;
        h2{
            font-size: pxToEm(76);
            @extend .fen ; 
            text-shadow: 0 0 27px rgba(0, 0, 0, 0.3);
        }
        h2 + div{
            font-size: pxToEm(34);
            margin-top: 24px;
            @extend .fen_b ;
            text-shadow: 0 0 27px rgba(0, 0, 0, 0.3);
        }
        @media (max-width:767px){
            margin-top: 0px;
            h2{
                font-size: pxToEm(36);
            }
            h2 + div{
                font-size: pxToEm(20);
                margin-top: 12px;
            }
            
        }
    }
    .slick-dots{
        width:100%;
        max-width: 1130px;
        padding:0 15px;
        margin:0;        
        text-align: right;
        z-index: 13;
        
        li.slick-active button::before{
            background: #fcdb8e;  
        }
        li{
            margin: 0 0 0 20px;
        }
        li button::before{
            background: #a0a0a0;
            top:0;
            left:0;
        }
        @media (min-width:768px){
            bottom:160px;
            left:50%;
            transform: translateX(-50%);
            li{
                width:12px;
                height: 12px !important; 
            }
            li button::before{
                width:12px;
                height: 12px;         
            }
        }
        @media (max-width:767px){
            padding:0 6.5%;
            bottom:13%;
            li{
                width:9px;
                height: 9px !important;
            }
            li button::before{
                width:9px;
                height: 9px;          
            }
        }
    }
    .mscroll{
        width:100%;
        max-width:1230px;
        padding:0 15px;
        @include position(absolute,$bottom:160px,$left:50%);
        transform: translateX(-50%);
        z-index: 12;
        a{
            width:20px;
            display: block;
        }
        @media (max-width:767px){
            bottom:10%;
        }
    }
}
.indx-about{    
    position: relative;
    z-index: 10;
    @include font-color(pxToEm(15),black);
    h2{
        @include font-color(pxToEm(34),$green);
        @extend .fen ;
        letter-spacing: 0.2px;
        line-height: 1;
    }
    h1{
        @include font-color(pxToEm(17),black);
        letter-spacing: 1.7px;
        line-height: 1;
        margin:12px 0 60px;
    }
    
    @media (min-width:768px){
        background: no-repeat url(../images/indx/indx-about-bg.png) center / 2000px;
        height: 616px;
        margin-bottom: 50px;
        margin-top: -167px;
        padding-top: 170px;
        letter-spacing: 1.5px;        
    }
    @media (max-width:767px){
        font-size: pxToEm(14);   
        padding-top: 15px;     
        margin-bottom: 40px; 
        h2{
            font-size: pxToEm(24);
        }
        h1{
            font-size: pxToEm(15);            
            margin-bottom: 30px;
        }
    }
    .mflex{
        display: flex;      
        flex-wrap: wrap;         
        @media (min-width:768px){
            > div{
                flex:0 0 33.333%;
                margin-bottom: 75px;
            }
            margin-top: 75px; 
        }
        @media (max-width:767px){
            > div{
                flex:0 0 100%;
                margin-top: 25px;
            }
        }
    }
}
.indx-prod{
    
    
    .mtitle{
        text-align: left;
        margin-bottom: 40px;
        h2 + div{
            margin-top: 12px;
        }
        &:after{
            top:-1px;            
            height: 53px;
        }
        @media (max-width:640px){
            margin-bottom: 30px;
            h2 + div{
                margin-top: 2px;
            }
            &:after{
                top:0px;            
                height: 40px;
            }
        }
    }
    .mright{
        position: absolute;
        bottom: 36px;
        @media (min-width:768px){
            left:calc(100% - 148px);
        }
        @media (max-width:767px){
            right:6.5%;
        }
    }
    .items{ 
        a{
            display: block;      
            position: relative;  
            overflow: hidden;
        }
          
        @media (min-width:769px){           
            a:hover{
                .bgcolor{
                    width:220px;
                    height: 105px;
                }
            }
            &.col-12{
                margin-bottom: 12px;
            }
            .bgcover{
                height: 360px;
            }  
        }
        @media (max-width:768px){
            &.col-12{
                margin-bottom: 4%;
            }
            .bgcover{
                padding-bottom: 66%;
            }  
        }
    }
}
.indx-line{
    background-color: #bfbfbf;
    height: 1px;;
    @media (min-width:768px){
        max-width: 1200px;
        width:calc(100% - 30px);        
        margin:40px auto 45px;
    }
    @media (max-width:767px){
        width:87%;
        margin: 15px auto 25px;
    }
}
.indx-cert{
    .mtitle{
        margin-bottom: 20px;
        &:after{
            height: 23px;
            top:1px;
        }
        h2{
            vertical-align: -3px;
            margin-right: 8px;
        }
        h2,h2+div{
            display: inline-block;
        }
        @media (max-width:640px){
            &:after{
                height: 20px;
                top:5px;
            }
        }
    }
    .mmore{
        margin-left: 20px;
    }
    .slider-cert{
        text-align: right;
        a{
            display: inline-block;
        }
    }
    @media (max-width:991px){
        .slider-cert{
            margin-top: 50px;
            text-align: center;
        }
    }
    @media (max-width:640px){
        .mmore{
            margin-left: 10px;
        }
        .slick-initialized .slick-slide{
            padding:0 20px;			
        }
        .btn_prev,.btn_next{
            z-index: 10;
            position: absolute;
            transform: translateY(-50%);
            top:50%;
            width:15px;
            display: block;
            cursor: pointer;
        }
        .btn_prev{
            left:-15px;	
        }
        .btn_next{
            right:-15px;	
        }
    }
}