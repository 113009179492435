/*----------------------通用設定------------------------*/
//清除float浮動
%cf,main[role^=main]  {
    zoom: 1;
    &:before,
    &:after {
        content: "";
        display: table;
        height: 0;
        overflow: hidden;
    }
    &:after {
        clear: both;
    }
}
.clearfix {
  @include clearfix();
}

//selection
p,h1,h2,h3,h4,b,strong,span,li,div,a,img{
    &::selection{
          color:#fff;
          background:#000;
        }
}
//a tag
a{outline: none;}
a,a:hover {
  text-decoration: none;
  transition: 0.4s;
  color:initial;
}
//phone
a[href^=tel] {
  color:$fontcolor;
  cursor: default;
  &:hover{
    color:$fontcolor;
  }
}
*:focus {
    outline: none;
}
img {
    vertical-align: middle;
    max-width: 100%;
}
ul{
  list-style-type: none;
}
//border-box
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/*----------------------編輯器------------------------*/
.editor_Box{
    @extend %cf;
    width: 100%;
    @include font-color(pxToEm(15),black);
    line-height: 1.5;
    ul,ol{
        padding-left: 40px;
    }
    img{
        max-width: 100%;
        height: auto !important;
        @media screen and (max-width: 768px) {
          width:100%;
        }
    }
    iframe{
         max-width: 100%;
          @media screen and (max-width: 767px) {
            height: auto !important;
          }
    }
    h1,h2,h3,h4,h5,h6,{
        margin: inherit;
    }
    p{
        margin-bottom: 18px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    
}
/*----------------------頁籤------------------------*/
.page{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    dt,dd{
      display: inline-block;   
      height: 20px;
    }
    a{
      font-size: pxToEm(16);
      display: block;
      line-height: 20px;
      color:#6A6A6A;
    }
    a.active{
      color:$pink;
    }
    
    dt a .svg{
      width:16px;
      height:18px;
      padding-top: 2px;
      path{
        fill: #6A6A6A;
      }
    }    
    @media (min-width: 641px) {
      
        a{
          margin:0 20px;
        }
        
        dd a:hover{
          color:$pink;
        }
        dt a:hover .svg{
          path{
            fill:$pink;
          }
        }
    }
    @media (min-width:992px){
      a{
        margin:0 25px;
      }
      .ltbn{
        margin-right: 20px;
      }
      .rtbn{
        margin-left: 20px;
      }
    }
    @media (max-width:640px){
      justify-content: space-around;
    }
    .nopage{
      opacity: 0;
      z-index: -1;
    }
    
}
/*----------------------go top------------------------*/
#topBtn{
  cursor: pointer;
  display:block;
  z-index:1;
  position: fixed;
  right:15px;
  bottom:10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s;
  background: no-repeat url(../images/gotop.png) top center/ contain;
  line-height: 0.8;
  letter-spacing: 0.8px;
  @include font-color(pxToEm(14),black);
  padding-top: 40px;
  &.open{
    opacity: 1;
    visibility: visible;
    z-index:100;
  }
  &.open{
    opacity: 1;
    z-index:100;
  }
  &.change{
    position: absolute;
  }
  @media (min-width: 641px) {
    &:hover{
      opacity: 0.5;
    }
  }  
  @media (max-width:640px){
    &.change{      
      color:white;
    }
  }
}
// custom
.text-center{text-align: center;}
.text-right{text-align:right;}
.text-justify{text-align: justify;}
.mpsrel{position: relative;}
.hide{display:none;}
.w10img{width:100%;}
.pos-middle{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
.bgcover{
  width:100%;
  height:0;
  background: center / cover;
}
.default_motion{
  transition: all 0.6s;
}

@media (min-width:769px){
  .show-sm{display:none;}
}
@media (max-width:768px){
  .show-lg{display:none;} 
  span.br{
    display:block;
  }
}


@keyframes lengthen{
  0% {height:0%;}
 90% {height:76px;}
 100% {height:76px;}
 
}

@-webkit-keyframes lengthen{
 0% {height:0%;}
 90% {height:76px;}
 100% {height:76px;}
}