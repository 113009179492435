@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
$mfont:"Noto Sans TC", sans-serif;
@font-face {
    font-family: "condensed-extrabold";
    src: url(../assets/condensed_extra_bold.ttf);
}
@font-face {
    font-family: "condensed-bold";
    src: url(../assets/condensed_bold.ttf);
}
@font-face {
    font-family: "condensed-Regular";
    src: url(../assets/condensed.ttf);
}

.fen_eb{
    font-family:  'condensed-extrabold', sans-serif;
}
.fen_b{
    font-family: 'condensed-bold', sans-serif;
}
.fen{
    font-family: 'condensed-Regular', sans-serif;
}
//-------------------------文字顏色-------------------------//
$pink:#ff777e;
$brwon:#b58a4c;
$blue:#00c7d6;
$yel:#fcdb8e;
$green:#2d4e37;
$fontcolor:#413523;
$menu:#2a2938;
$bodycolor:white;

$acolor:#313131;

//#8f9196 line