.prod{
    
    text-align: center;
    .bgcolor{
        background-color: rgba(black,0.5);
        width: 100%;
        height: 100%;
        @extend .pos-middle ;
        z-index: 2;
        @extend .default_motion ;
    }
    .txt{
        @extend .pos-middle ;
        z-index: 3;
    }
    .txt h4{
        @include font-color(pxToEm(34),white);
        letter-spacing: 0.2px;
        line-height: 30px;
        margin-bottom: 2px;
        @extend .fen;
        @media (max-width:768px){
            font-size: pxToEm(25);
            line-height: 25px;
        }
    }
    .txt span{
        @include font-color(pxToEm(20),white);
        letter-spacing: 0.1px;
        @media (max-width:768px){
            font-size: pxToEm(15);
        }
    }
    .back{
        margin-top: 45px;
    }
}
.prodlist{
    a{
        display: block;      
        position: relative;  
    }
    .bgcover{
        padding-bottom: 66%;
    }    
    @media (min-width:769px){
        max-width: 1130px;
        padding:0 9px;
        a:hover{
            .bgcolor{
                width:220px;
                height: 105px;
            }
        }
        .col-12{
            padding-left:6px;
            padding-right: 6px;
            margin-bottom: 12px;
        }
    }
    @media (max-width:768px){
        .col-12{
            margin-bottom: 4%;
        }
    }
}
.prodbanner{     
    margin-bottom: 25px;
    @media (min-width:769px){
        .bgcover{
            height: 360px;
        } 
    }
    @media (max-width:768px){
        .bgcover{
            padding-bottom: 66%;
        } 
    }
}
.prodtable{
    h2{
        letter-spacing: 0.9px;
        line-height: 1.2;
        @include font-color(pxToEm(17),$green,700);
        margin-bottom: 20px;
        text-align: center;
        @media (max-width:640px){
            font-size: pxToEm(15);
            margin-bottom: 12px;
        }
    }
    
    @media (min-width:992px){
        max-width: 1130px;
        ul.items{
            margin-bottom: 35px;
            display: table;
            border:1px solid $green;  
            width:100%;       
            @include font-color(pxToEm(15),black);  
            letter-spacing: 0.8px;
        }
        li{
            display: table-row; 
        }
        li.thead{
            background-color: $green;
            div{
                line-height: 40px;
                text-align: center;
                color:white;
                padding:0;
            }
        }
        div{
            vertical-align:middle;
            display: table-cell;
            border:solid 1px rgba($green,0.2);       
            width:78px;     
            padding:15px 13px;
            text-align: center;
        }
        div:nth-child(1),div:nth-child(8){
            width:170px;
            text-align: left;
        }
        div:nth-child(4){
            width:110px;
            text-align: left;
        }
        div:nth-child(7){
            width:calc(100% - 762px);
            text-align: left;
        }
        i{
            display: none;
        }
    }
    @media (max-width:991px){
        ul.items{
            max-width: 670px;
            margin:0 auto 30px;
            @include font-color(pxToEm(14),black); 
            letter-spacing: 0.8px; 
        }
        li.thead{
            display: none;
        }
        li{
            display: flex;
            flex-wrap: wrap;     
            margin-bottom: 25px;
            border:1px solid $green;
        }
        div{
            flex:0 0 100%;
            padding:10px;
            border-bottom:1px solid rgba($green,0.2);
            display: flex;
            flex-wrap: wrap; 
            justify-content: space-between;
            align-items: center;
        }
        div:nth-child(1){
            background-color: $green;
            color: white;
            span{
                text-align: right;
            }
        }
        div:nth-child(2),div:nth-child(3),div:nth-child(4),div:nth-child(5){
            flex:0 0 50%;
        }
        div:nth-child(2),div:nth-child(4){
            border-right: 1px solid rgba($green,0.2);
        }
        div:nth-child(7),div:nth-child(8){
            > *{
                flex:0 0 100%;
            }
            i{
                margin-bottom: 12px;
            }
        }
        span.mflex{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 25px;
            }
        }
    }
}